import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../config/axios";
import { endBlocking, startBlocking } from "./loadingSlice";

// Thunk to handle the API call
export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (_, { dispatch }) => {
    dispatch(startBlocking());
    try {
      // const response = await Axios.get("/notifications");
      // const resData = response.data;
      const notifications1 = [
        {
          id: 1,
          title: "Apple",
          status:"Buy",
          quantity:"1000",
          date:"16",
          month:"Sep",
          time: "11:11 PM",
          description: "Your purchase of Apple Stocks was Succe...",
        },
        {
          id: 1,
          title: "Apple",
          status:"Buy",
          quantity:"1000",
          date:"16",
          month:"Sep",
          time: "11:11 PM",
          description: "Your purchase of Apple Stocks was Successfully",
        },
        {
          id: 2,
          title: "Tesla",
          status:"sold",
          quantity:"5000",
          date:"16",
          month:"Sep",
          time: "03:20 PM",
          description: "Your sell of Tesla Stocks was Successsfuly completed",
        },
        {
          id: 3,
          title: "Tesla",
          status:"sold",
          quantity:"5000",
          date:"13",
          month:"Sep",
          time: "03:20 PM",
          description: "Your sell of Tesla Stocks was Successfully",
        },
        
        {
          id: 4,
          title: "Microsoft",
          status:"Buy",
          quantity:"650",
          date:"13",
          month:"Sep",
          time: "02:34 PM",
          description: "Your purchase of Microsoft Stocks was Successfully",
        },
        {
          id: 5,
          title: "Tesla",
          status:"sold",
          quantity:"500",
          date:"12",
          month:"Sep",
          time: "03:20 PM",
          description: "Your sell of Tesla Stocks was Successfully",
        },
       
        
      ];
      const resData = {
        notifications: notifications1,
      };
      return resData;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(endBlocking());
    }
  }
);

export const deleteNotifications = createAsyncThunk(
  "notifications/deleteNotifications",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      await Axios.delete(`/notifications/${id}`);
      return id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message); // Return error to handle it in the UI if necessary
    }
  }
);

export const updateNotificationCount = createAsyncThunk(
  "notifications/updateNotificationCount",
  async () => {
    try {
      const data = await Axios.get(`/notifications/update-count`);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
    }
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    data: [],
    loading: false,
    error: null,
    unreadCount: 0,
  },
  reducers: {
    // Other non-async reducers if necessary
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.notifications;
        state.unreadCount = action.payload.unreadCount;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          (notification) => notification.id !== action.payload
        );
      })
      .addCase(deleteNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateNotificationCount.pending, (state) => {
        state.error = null;
      })
      .addCase(updateNotificationCount.fulfilled, (state) => {
        state.unreadCount = 0;
      })
      .addCase(updateNotificationCount.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default notificationSlice.reducer;
