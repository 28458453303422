import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { selectDialogOpen } from "../../Redux/dialogSlice";
import { useSelector } from "react-redux";
import BlockDialogUI from "../BlockDialogUI";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ReactComponent as EyeIcon } from "../../assets/Icon/eye.svg";

const TableActions = ({
  openEditBox,
  viewURL,
  description = "",
  onDeleted,
  viewTooltip = "",
  CopyTooltip = "",
  copyText,
  onRefreshKey,
  refreshKeyTooltip = "",
  onDownload,
}) => {
  const isOpen = useSelector(selectDialogOpen);
  const { role } = useSelector((state) => state.auth);

  const [open, setOpen] = React.useState(isOpen);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack direction="row">
        {onDownload && (
          <Tooltip title="Download Key" arrow>
            <IconButton size="small" onClick={onDownload}>
              <FileDownloadIcon fontSize="14px" color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {CopyTooltip && (
          <Tooltip title={CopyTooltip} arrow>
            <IconButton
              size="small"
              onClick={() => navigator.clipboard.writeText(copyText)}
            >
              <ContentCopyIcon fontSize="14px" color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {refreshKeyTooltip && (
          <Tooltip title={refreshKeyTooltip} arrow>
            <IconButton size="small" onClick={onRefreshKey}>
              <RefreshIcon fontSize="14px" color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {viewTooltip && (
          <Tooltip title={viewTooltip} arrow>
            <IconButton size="small" onClick={viewURL}>
              <EyeIcon fontSize="14px" />
            </IconButton>
          </Tooltip>
        )}
        {openEditBox && role === "SUPER_ADMIN" && (
          <Tooltip title="Edit" arrow>
            <IconButton size="small" onClick={() => openEditBox(true)}>
              <EditIcon fontSize="14px" sx={{ color: "#6259ca" }} />
            </IconButton>
          </Tooltip>
        )}
        {onDeleted && role === "SUPER_ADMIN" && (
          <Tooltip title="Delete" arrow>
            <IconButton size="small" onClick={handleClickOpen}>
              <DeleteOutlineIcon fontSize="14px" color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <BlockDialogUI>
          <DialogTitle
            style={{ backgroundColor: "#f2674a", color: "white" }}
            id="alert-dialog-title"
          >
            Delete
          </DialogTitle>

          <DialogContent sx={{ minWidth: "600px", backgroundColor: "#2c2c2c" }}>
            <DialogContentText style={{ color: "white" }} sx={{ pt: 2 }}>
              {" "}
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: "#2c2c2c" }}>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => onDeleted(setOpen)} color="error">
              <Typography sx={{ color: "red" }}>Delete</Typography>
            </Button>
          </DialogActions>
        </BlockDialogUI>
      </Dialog>
    </>
  );
};

export default TableActions;
