import { CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function BlockDialogUI({ children }) {
  const { isBlockingDialog } = useSelector((state) => state.loading);
  return (
    <div style={{ position: "relative" }}>
      {isBlockingDialog && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div className="loader">
            <CircularProgress size="40px" color="primary" />
          </div>{" "}
        </div>
      )}
      <div
        style={{
          opacity: isBlockingDialog ? 0.9 : 1,
          pointerEvents: isBlockingDialog ? "none" : "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
}
