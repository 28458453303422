import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../config/axios";
import { endLoading, startLoading } from "./loadingSlice";

// Utility function to make API requests
const fetchData = async (endpoint, dispatch) => {
  try {
    dispatch(startLoading());
    const response = await Axios.get(
      `/organization/admin/dashboard/${endpoint}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch data from ${endpoint}: ${error.message}`);
  } finally {
    dispatch(endLoading());
  }
};

// Thunks using the utility function
export const getTotalStockLoan = createAsyncThunk(
  "data/fetchTotalStockLoan",
  async (_, { dispatch }) => {
    const data = await fetchData("total-stock-loan", dispatch);
    return data;
  }
);

export const getTotalLoanBookValue = createAsyncThunk(
  "data/fetchTotalLoanBookValue",
  async (_, { dispatch }) => fetchData(`total-loan-book-value`, dispatch)
);

export const getSectorWise = createAsyncThunk(
  "data/fetchTotalLoanBookValueSectorWise",
  async (parameter, { dispatch }) => {
    const { page, rowsPerPage, search } = parameter;
    return fetchData(
      `sectorwise?pageNumber=${page}&pageSize=${rowsPerPage}5&searchText=${search}`,
      dispatch
    );
  }
);

export const getTotalNumberOfLoans = createAsyncThunk(
  "data/fetchTotalNumberOfLoans",
  async (_, { dispatch }) => fetchData("total-number-loans", dispatch)
);

export const getTotalPortfolioValue = createAsyncThunk(
  "data/total-portfolio-value'",
  async (_, { dispatch }) => fetchData("total-portfolio-value", dispatch)
);

export const getStockWise = createAsyncThunk(
  "data/fetchTotalNumberOfLoansStockWise",
  async (parameter, { dispatch }) => {
    const { page, rowsPerPage, search, sector_id } = parameter;

    return fetchData(
      `stockwise?pageNumber=${page}&pageSize=${rowsPerPage}&searchText=${search}&sectorId=${sector_id}`,
      dispatch
    );
  }
);

export const getAveragesLoans = createAsyncThunk(
  "data/fetchAveragesLoans",
  async (_, { dispatch }) => {
    const data = fetchData("averages-loans", dispatch);
    return data;
  }
);

export const getAveragesActiveLoans = createAsyncThunk(
  "data/average_active_loan_amount_per_user",
  async (_, { dispatch }) => {
    const data = await fetchData("averages-active-loans", dispatch);
    return data;
  }
);

export const getRepaymentSchedule = createAsyncThunk(
  "data/fetchRepaymentSchedule",
  async (_, { dispatch }) => fetchData("repayment-schedule", dispatch)
);
