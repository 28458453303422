// SearchInput.jsx
import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

const SearchInput = ({ onChange }) => {
  const debounceOnChange = React.useMemo(
    () => debounce(onChange, 800),
    [onChange]
  );

  return (
    <TextField
      size="small"
      fullWidth
      border="none"
      style={{outline:"none !important",border:"none !important"}}
      placeholder="Search..."
      onChange={(e) => debounceOnChange(e.target.value)}
      InputProps={{
        disableUnderline: true, // Removes the underline (if using filled or standard variant)
        sx: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderRight: "none", // Removes the border for the outlined variant
          }
        },
      }}
      
      // onClick={(e) => debounceOnChange(e.target.value)}
      // InputProps={{
        // startAdornment: (
        //   <InputAdornment position="start">
        //     {/* <SearchIcon sx={{ color: "#918e8e" }} /> */}
        //   </InputAdornment>
        // ),
      // }}
    />
  );
};

export default SearchInput;
