import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isBlocking: false,
  isLoading: false,
  isBlockingDialog: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    startBlocking(state) {
      state.isBlocking = true;
    },
    endBlocking(state) {
      state.isBlocking = false;
    },
    startBlockingDialog(state) {
      state.isBlockingDialog = true;
    },
    endBlockingDialog(state) {
      state.isBlockingDialog = false;
    },
  },
});

export const {
  startLoading,
  endLoading,
  startBlocking,
  endBlocking,
  startBlockingDialog,
  endBlockingDialog,
} = loadingSlice.actions;

export default loadingSlice.reducer;
