import { createSlice } from "@reduxjs/toolkit";

import {
  getAveragesActiveLoans,
  getAveragesLoans,
  getRepaymentSchedule,
  getTotalLoanBookValue,
  getSectorWise,
  getTotalNumberOfLoans,
  getTotalStockLoan,
  getStockWise,
  getTotalPortfolioValue,
} from "./api";

// Utility function to handle state updates
const handlePending = (state, key) => {
  state[key].loading = true;
};

const handleFulfilled = (state, key, action, prop = "data") => {
  state[key].loading = false;
  state[key][prop] = action.payload;
};

const handleRejected = (state, key, action) => {
  state[key].loading = false;
  state[key].error = action.error.message;
};

const dashBoardSlice = createSlice({
  name: "dashboard",

  initialState: {
    totalStockLoan: { data: 0, loading: false, error: {} },
    totalLoanBookValue: { data: 0, loading: false, error: {} },
    totalNumberOfLoans: { data: 0, loading: false, error: {} },
    averagesLoans: { data: 0, loading: false, error: {} },
    averagesActiveLoans: { data: 0, loading: false, error: {} },
    repaymentSchedule: { data: [], loading: false, error: {} },
    totalPortfolioValue: { data: {}, loading: false, error: {} },
    sectorData: {
      data: [],
      totalRows: 0,
      loading: false,
    },
    stockData: { data: [], totalRows: 0, loading: false },
    backUrl: localStorage.getItem("backUrl"),
  },

  reducers: {
    setBackUrl(state, action) {
      localStorage.setItem("backUrl", action.payload);
    },
  },

  extraReducers: (builder) => {
    // getTotalStockLoan
    builder
      .addCase(getTotalStockLoan.pending, (state) =>
        handlePending(state, "totalStockLoan")
      )
      .addCase(getTotalStockLoan.fulfilled, (state, action) => {
        state.sectorData.data = action.payload.data;
        state.sectorData.loading = false;
        state.sectorData.totalRows = action.payload.totalRecord;
      })
      .addCase(getTotalStockLoan.rejected, (state, action) =>
        handleRejected(state, "totalStockLoan", action)
      );

    // getTotalLoanBookValue
    builder
      .addCase(getTotalLoanBookValue.pending, (state) =>
        handlePending(state, "totalLoanBookValue")
      )
      .addCase(getTotalLoanBookValue.fulfilled, (state, action) =>
        handleFulfilled(state, "totalLoanBookValue", action)
      )
      .addCase(getTotalLoanBookValue.rejected, (state, action) =>
        handleRejected(state, "totalLoanBookValue", action)
      );

    // getSectorWise
    builder
      .addCase(getSectorWise.pending, (state) =>
        handlePending(state, "sectorData")
      )
      .addCase(getSectorWise.fulfilled, (state, action) => {
        state.sectorData.data = action.payload.data;
        state.sectorData.loading = false;
        state.sectorData.totalRows = action.payload.totalRecord;
      })
      .addCase(getSectorWise.rejected, (state, action) =>
        handleRejected(state, "sectorData", action)
      );

    // getTotalNumberOfLoans
    builder
      .addCase(getTotalNumberOfLoans.pending, (state) =>
        handlePending(state, "totalNumberOfLoans")
      )
      .addCase(getTotalNumberOfLoans.fulfilled, (state, action) =>
        handleFulfilled(state, "totalNumberOfLoans", action)
      )
      .addCase(getTotalNumberOfLoans.rejected, (state, action) =>
        handleRejected(state, "totalNumberOfLoans", action)
      );

    builder
      .addCase(getStockWise.pending, (state) =>
        handlePending(state, "stockData")
      )
      .addCase(getStockWise.fulfilled, (state, action) => {
        state.stockData.data = action.payload.data;
        state.stockData.loading = false;
        state.stockData.totalRows = action.payload.totalRecord;
      })
      .addCase(getStockWise.rejected, (state, action) =>
        handleRejected(state, "stockData", action)
      );

    // getAveragesLoans
    builder
      .addCase(getAveragesLoans.pending, (state) =>
        handlePending(state, "averagesLoans")
      )
      .addCase(getAveragesLoans.fulfilled, (state, action) =>
        handleFulfilled(state, "averagesLoans", action)
      )
      .addCase(getAveragesLoans.rejected, (state, action) =>
        handleRejected(state, "averagesLoans", action)
      );

    // getAveragesActiveLoans
    builder
      .addCase(getAveragesActiveLoans.pending, (state) =>
        handlePending(state, "averagesActiveLoans")
      )
      .addCase(getAveragesActiveLoans.fulfilled, (state, action) =>
        handleFulfilled(state, "averagesActiveLoans", action)
      )
      .addCase(getAveragesActiveLoans.rejected, (state, action) =>
        handleRejected(state, "averagesActiveLoans", action)
      );

    // getRepaymentSchedule
    builder
      .addCase(getRepaymentSchedule.pending, (state) =>
        handlePending(state, "repaymentSchedule")
      )
      .addCase(getRepaymentSchedule.fulfilled, (state, action) =>
        handleFulfilled(state, "repaymentSchedule", action)
      )
      .addCase(getRepaymentSchedule.rejected, (state, action) =>
        handleRejected(state, "repaymentSchedule", action)
      );

    // totalPortfolioValue

    builder
      .addCase(getTotalPortfolioValue.pending, (state) =>
        handlePending(state, "totalPortfolioValue")
      )
      .addCase(getTotalPortfolioValue.fulfilled, (state, action) =>
        handleFulfilled(state, "totalPortfolioValue", action)
      )
      .addCase(getTotalPortfolioValue.rejected, (state, action) =>
        handleRejected(state, "totalPortfolioValue", action)
      );
  },
});
export const { setBackUrl } = dashBoardSlice.actions;

export default dashBoardSlice.reducer;
