import React, { useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import { PublicRoute } from "../Router/ProtectedRoute";
import * as Switcherdata from "../data/Switcherdata/Switcherdata";

const Auth = () => {
  useLayoutEffect(() => {
    Switcherdata.dark();
  }, []);
  return (
    <PublicRoute>
      <div>
        <Outlet />
      </div>
    </PublicRoute>
  );
};

export default Auth;
