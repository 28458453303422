// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F2674A", // Custom primary color
    },
    secondary: {
      main: "#dc004e", // Custom secondary color
    },
    error: {
      main: "#ff6969", // Custom error color
    },
    warning: {
      main: "#ffa726", // Custom warning color
    },
    info: {
      main: "#2196f3", // Custom info color
    },
    success: {
      main: "#4caf50", // Custom success color
    },
    background: {
      default: "##F2674A", // Custom background color
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#434343", // Border color
            },
            "&:hover fieldset": {
              borderColor: "#918e8e", // Border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#F2674A", // Border color when focused
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#434343", // Border color
            },
            "&:hover fieldset": {
              borderColor: "#918e8e", // Border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#F2674A", // Border color when focused
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              color: "#ffffff", // Text color for the input
            },
          },
        },
      },
    },
  },
});

export default theme;
