import { configureStore } from "@reduxjs/toolkit";
import loadingReducer from "./loadingSlice";
import dialogReducer from "./dialogSlice";
import authReducer from "./authSlice";
import notificationReducer from "./notificationSlice";
import dashBoardSlice from "./dashBoardSlice";

const store = configureStore({
  reducer: {
    loading: loadingReducer,
    dialog: dialogReducer,
    auth: authReducer,
    notifications: notificationReducer,
    dashboard: dashBoardSlice,
  },
});

export default store;
