import { useState, useCallback } from "react";

const useDialogs = () => {
  const [openDialogs, setOpenDialogs] = useState({});

  const openDialog = useCallback((dialogName) => {
    setOpenDialogs((prev) => ({ ...prev, [dialogName]: true }));
  }, []);

  const closeDialog = useCallback((dialogName) => {
    setOpenDialogs((prev) => ({ ...prev, [dialogName]: false }));
  }, []);

  const isDialogOpen = useCallback(
    (dialogName) => !!openDialogs[dialogName],
    [openDialogs]
  );

  return { openDialog, closeDialog, isDialogOpen };
};

export default useDialogs;
