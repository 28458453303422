import { Navigate, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  const { user, role } = useSelector((state) => state.auth);

  if (!user) {
    return <Navigate to="/login" />;
  } else if (
    role === "ORGANIZATION" &&
    "/organization/add/".includes(location.pathname)
  ) {
    return <Navigate to="/dashboard" />;
  }
  return children;
};

export const PublicRoute = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  if (user) {
    return <Navigate to="/dashboard" />;
  }
  return children;
};
